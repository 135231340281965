/* eslint-disable no-case-declarations */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import localforage from 'localforage';
// import engineWorker from 'Data/worker';
import PropTypes from 'prop-types';
import DownLoadDataContainer from 'Components/DownloadData/DownloadDataContainer';
import { H4, Intent, Toaster } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import './DataLoader.css';
import transformData from './utils/transformData';

const toaster = Toaster.create();

const DataLoader = (props) => {
  const sharedWorker = React.useMemo(() => new SharedWorker(new URL('Data/engineWorker.js', import.meta.url) /* webpackChunkName: "my-worker" */), []);

  // DERIVED STATE
  const { tableName, mainDateField, filterDimensions } = props.datasetParams;

  // STATE
  const dispatch = useDispatch();
  const [dataInfo, setDataInfo] = React.useState();

  // LOAD DATA ON MOUNT
  React.useEffect(() => {
    sharedWorker.port.start();
    sharedWorker.port.onmessage = ({ data }) => {
      const { type } = data;
      // console.log(type);

      switch (type) {
        case 'loadData':
          const { nbRows, fields, lastDate, filterValues } = data.loadDataOutput[props.datasetParams.tableName];
          dispatch({
            type: 'UDPATE_DATA_INFO',
            module: props.module,
            subModule: props.subModule,
            dataInfo: { nbRows, fields, lastDate },
          });
          dispatch({
            type: 'UPDATE_ALL_CONTROL_VALUES',
            module: props.module,
            subModule: props.subModule,
            filterValues,
          });
          setDataInfo({ nbRows, fields, lastDate });
          break;
        default:
          break;
      }
    };

    // Load Data
    const asyncLoadLocalData = async () => {
      const localData = await localforage.getItem(props.datasetParams.tableName);
      if (localData) {
        // loadDataMutation.mutate(localData);
        sharedWorker.port.postMessage({
          type: 'loadData',
          datasetParams: { tableName, mainDateField, filterDimensions },
          data: localData,
        });
      } else {
        toaster.show({ message: 'No data on this device.', intent: Intent.WARNING, icon: 'warning-sign' });
      }
    };
    asyncLoadLocalData();
  }, []);

  const onDataLoaded = (rawData) => {
    const transformedData = transformData(rawData, props.datasetParams.headersRemaps, props.datasetParams.transformRow);
    localforage.setItem(props.datasetParams.tableName, transformedData);

    sharedWorker.port.postMessage({
      type: 'loadData',
      datasetParams: { tableName, mainDateField, filterDimensions },
      data: transformedData,
    });
  };

  return (
    <div className="DataLoader">
      <header>
        <section className="titleRow">
          <H4>{props.pageTitle}</H4>
          <article className="refreshData">
            <DownLoadDataContainer
              mainFile={props.datasetParams.mainFile}
              runDetailsFile={props.datasetParams.runDetailsFile}
              localLastDate={dataInfo?.lastDate}
              onDataLoaded={onDataLoaded}
              showDLButton
            />
          </article>
        </section>
      </header>
      <main>{props.children}</main>
    </div>
  );
};

DataLoader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  subModule: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  datasetParams: PropTypes.shape({
    mainFile: PropTypes.string.isRequired,
    runDetailsFile: PropTypes.string.isRequired,
    tableName: PropTypes.string,
    mainDateField: PropTypes.string,
    headersRemaps: PropTypes.objectOf(PropTypes.string),
    calculatedMetrics: PropTypes.objectOf(
      PropTypes.shape({
        numerator: PropTypes.string,
        denominator: PropTypes.string,
        format: PropTypes.string,
      }),
    ),
    transformRow: PropTypes.func,
    filterDimensions: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
};

export default DataLoader;
